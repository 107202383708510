import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Image } from 'cloudinary-react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import moment from 'moment'

import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { getQueries } from '../actions/queryActions'
import { GET_QUERY_RESET } from '../constants/queryConstants'

const Query = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [imageModal, setImageModal] = useState(false)
  const [images, setImages] = useState([])
  // * Dates
  const [dateModal, setDateModal] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection',
    },
  ])
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [dateRange, setDateRange] = useState(false)

  useEffect(() => {
    let dateObject = state[0]
    let { startDate, endDate } = dateObject

    startDate = moment(startDate).toISOString()
    endDate = moment(endDate).toISOString()

    setFrom(startDate)
    setTo(endDate)
  }, [state])

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getQueries())
  }, [])

  // * Get Queries
  const getQueryInfo = useSelector((state) => state.getQueryInfo)
  const { errorGetQuery, getQueryData } = getQueryInfo

  useEffect(() => {
    dispatch({ type: GET_QUERY_RESET })
    if (getQueryData) {
      setLoading(false)
      setData(getQueryData)
    } else if (errorGetQuery) {
      setLoading(false)
      toast(errorGetQuery, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getQueryData, errorGetQuery])

  const headCells = [
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'description',
      title: 'Description',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {rowData.type === 'Safety' ? rowData.description : 'N/A'}
          </p>
        )
      },
    },
    {
      field: 'user',
      title: 'User',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {rowData.type === 'Safety' ? rowData.user.username : 'N/A'}
          </p>
        )
      },
    },
    {
      field: 'images',
      title: 'Images',
      render: (rowData) => {
        return (
          <>
            {rowData.type === 'Safety' ? (
              <div
                className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
                onClick={() => openImages(rowData.images)}
              >
                View
              </div>
            ) : (
              'N/A'
            )}
          </>
        )
      },
    },
    {
      field: 'createdAt',
      title: 'Created On',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        )
      },
    },
  ]

  if (loading) {
    return <Loading />
  }

  const openImages = (img) => {
    setImages(img)
    setImageModal(true)
  }

  const closeImagesModal = () => {
    setImages([])
    setImageModal(false)
  }

  const submitDateRange = () => {
    setDateRange(true)
    setDateModal(false)

    // * dispatch
    dispatch(getQueries(from, to))
  }

  return (
    <>
      <div className='w-full h-full'>
        <div className='flex flex-row justify-between mb-6'>
          <div className='flex flex-row'>
            <h1 className='text-2xl font-semibold mb-4 mr-6'>Queries</h1>
            {dateRange && (
              <p
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  fontSize: 16,
                }}
              >
                ({moment(from).format('YYYY-MM-DD')} -{' '}
                {moment(to).format('YYYY-MM-DD')})
              </p>
            )}
          </div>
          <button
            className='bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
            type='button'
            onClick={() => setDateModal(true)}
          >
            Filters
          </button>
        </div>
        <MatTable headCells={headCells} data={data} type='Queries' />
        {imageModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-2xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Images</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex flex-wrap items-center'>
                    {images.map((item) => (
                      <Image
                        cloudName='parkoye'
                        publicId={item}
                        key={item}
                        width='280'
                        height='180'
                        crop='scale'
                        className='m-4'
                      />
                    ))}
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeImagesModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {dateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-full'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Date Range</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <DateRangePicker
                      onChange={(item) => setState([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction='horizontal'
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={() => setDateModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={submitDateRange}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Query
