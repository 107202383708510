import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import {
  userLoginReducer,
  addUserReducer,
  getUsersReducer,
  newPasswordUserReducer,
  updateUserReducer,
} from './reducers/userReducers'
import { deleteQueryReducer, getQueryReducer } from './reducers/queryReducers'
import {
  addMessageReducer,
  deleteMessageReducer,
  getMessagesReducer,
} from './reducers/messageReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  getUsersInfo: getUsersReducer,
  addUserInfo: addUserReducer,
  updateUserInfo: updateUserReducer,
  newPasswordUserInfo: newPasswordUserReducer,
  getQueryInfo: getQueryReducer,
  getMessagesInfo: getMessagesReducer,
  addMessageInfo: addMessageReducer,
  deleteMessageInfo: deleteMessageReducer,
  deleteQueryInfo: deleteQueryReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const inititalState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
