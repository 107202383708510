import axios from 'axios'
import {
  DELETE_QUERY_FAIL,
  DELETE_QUERY_REQUEST,
  DELETE_QUERY_SUCCESS,
  GET_QUERY_FAIL,
  GET_QUERY_REQUEST,
  GET_QUERY_SUCCESS,
} from '../constants/queryConstants'
import { BASE_URL } from '../constants/URL.js'

export const getQueries = (from, to) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_QUERY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `${BASE_URL}/queries?from=${from}&to=${to}`,
      config
    )

    dispatch({
      type: GET_QUERY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_QUERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteQuery = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_QUERY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${BASE_URL}/queries/${id}`, {}, config)

    dispatch({
      type: DELETE_QUERY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_QUERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
