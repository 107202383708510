import axios from 'axios'
import {
  ADD_MESSAGE_FAIL,
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
} from '../constants/messageConstants'
import { BASE_URL } from '../constants/URL.js'

export const getMessages = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MESSAGES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${BASE_URL}/messages`, config)

    dispatch({
      type: GET_MESSAGES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_MESSAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addMessage =
  (title, description, type, image) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_MESSAGE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `${BASE_URL}/messages`,
        { title, description, type, image },
        config
      )

      dispatch({
        type: ADD_MESSAGE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ADD_MESSAGE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteMessage = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_MESSAGE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${BASE_URL}/messages/${id}`, {}, config)

    dispatch({
      type: DELETE_MESSAGE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_MESSAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
