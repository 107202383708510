import {
  DELETE_QUERY_FAIL,
  DELETE_QUERY_REQUEST,
  DELETE_QUERY_RESET,
  DELETE_QUERY_SUCCESS,
  GET_QUERY_FAIL,
  GET_QUERY_REQUEST,
  GET_QUERY_RESET,
  GET_QUERY_SUCCESS,
} from '../constants/queryConstants'

export const getQueryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUERY_REQUEST:
      return { loadingGetQuery: true }
    case GET_QUERY_SUCCESS:
      return { loadingGetQuery: false, getQueryData: action.payload }
    case GET_QUERY_FAIL:
      return { loadingGetQuery: false, errorGetQuery: action.payload }
    case GET_QUERY_RESET:
      return {}
    default:
      return state
  }
}

export const deleteQueryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_QUERY_REQUEST:
      return { loadingDeleteQuery: true }
    case DELETE_QUERY_SUCCESS:
      return { loadingDeleteQuery: false, deleteQueryData: action.payload }
    case DELETE_QUERY_FAIL:
      return { loadingDeleteQuery: false, errorDeleteQuery: action.payload }
    case DELETE_QUERY_RESET:
      return {}
    default:
      return state
  }
}
