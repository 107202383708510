import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Select from '../components/Select'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { addMessage, getMessages } from '../actions/messageActions'
import {
  ADD_MESSAGE_RESET,
  GET_MESSAGES_RESET,
} from '../constants/messageConstants'
import moment from 'moment'
import { Image } from 'cloudinary-react'

const Message = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addMessageModal, setAddMessageModal] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDesciption] = useState('')
  const [image, setImage] = useState('')
  const [type, setType] = useState('')
  const [imageModal, setImageModal] = useState(false)

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getMessages())
  }, [])

  // * Get Users
  const getMessagesInfo = useSelector((state) => state.getMessagesInfo)
  const { errorGetMessages, getMessagesData } = getMessagesInfo

  useEffect(() => {
    dispatch({ type: GET_MESSAGES_RESET })
    if (getMessagesData) {
      setLoading(false)
      setData(getMessagesData)
    } else if (errorGetMessages) {
      setLoading(false)
      toast(errorGetMessages, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getMessagesData, errorGetMessages])

  const closeAddMessageModal = () => {
    setTitle('')
    setDesciption('')
    setImage('')
    setType('')
    setAddMessageModal(false)
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = ''
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const addMessageHandler = async () => {
    if (!title || !type) {
      toast('Title and type are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      if (type === 'Notification') {
        if (!description) {
          toast('Description is mandatory', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
          })
          return
        }
      }

      if (
        type === 'Health and Safety Calender' ||
        type === 'Training Calender'
      ) {
        if (!image) {
          toast('Image is mandatory', {
            type: 'error',
            hideProgressBar: true,
            autoClose: 2000,
          })
          return
        }
      }

      let base64 = ''

      if (image) {
        base64 = await getBase64(image)
      }

      dispatch(addMessage(title, description, type, base64))
    }
  }

  const addMessageInfo = useSelector((state) => state.addMessageInfo)
  const { loadingAddMessage, errorAddMessage, addMessageData } = addMessageInfo

  useEffect(() => {
    dispatch({ type: ADD_MESSAGE_RESET })
    if (addMessageData) {
      closeAddMessageModal()
      toast(addMessageData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getMessages())
      }, 1000)
    } else if (errorAddMessage) {
      toast(errorAddMessage, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addMessageData, errorAddMessage])

  const headCells = [
    {
      field: 'title',
      title: 'Title',
    },
    {
      field: 'description',
      title: 'Description',
    },
    {
      field: 'image',
      title: 'Image',
      render: (rowData) => {
        return (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => openImage(rowData.image)}
          >
            View
          </div>
        )
      },
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'createdAt',
      title: 'Created At',
      render: (rowData) => {
        return (
          <p className='text-sm'>
            {moment(rowData.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </p>
        )
      },
    },
  ]

  const openImage = (img) => {
    setImage(img)
    setImageModal(true)
  }

  const closeImageModal = () => {
    setImage([])
    setImageModal(false)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Messages</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => setAddMessageModal(true)}
              text='Add a Message'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='Messages' />
        {addMessageModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add Message</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='w-full'
                      name='Title *'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <Input
                      width='w-full mb-4 mt-4'
                      name='Description'
                      value={description}
                      onChange={(e) => setDesciption(e.target.value)}
                    />
                    <Input
                      width='flex-1'
                      type='file'
                      name='PDF *'
                      accept='application/pdf'
                      // value={image}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                    <Select
                      width='w-full mb-4 mt-4'
                      name='Type *'
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      options={[
                        { id: 'Notification', title: 'Notification' },
                        {
                          id: 'Health and Safety Calender',
                          title: 'Health and Safety Calender',
                        },
                        { id: 'Training Calender', title: 'Training Calender' },
                      ]}
                    />
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddMessageModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addMessageHandler}
                      disabled={loadingAddMessage}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {imageModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-2xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Image</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex flex-wrap items-center'>
                    <Image
                      cloudName='parkoye'
                      publicId={image}
                      key={image}
                      width='280'
                      height='180'
                      crop='scale'
                      className='m-4'
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeImageModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default Message
