import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Login from './screens/Login'
import User from './screens/User'
import SideBar from './components/SideBar'
import Container from './components/Container'
import { useSelector } from 'react-redux'
import Query from './screens/Query'
import Message from './screens/Message'

function App() {
  return (
    <Router>
      <ToastContainer />
      <Switch>
        <Route path='/' component={Login} exact />
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  )
}

const DefaultContainer = () => {
  return (
    <Container>
      <SideBar />
      <div className='mx-auto py-10 px-6 bg-gray-100 overflow-x-auto w-4/5'>
        <Route path='/users' component={User} exact />
        <Route path='/queries' component={Query} exact />
        <Route path='/messages' component={Message} exact />
      </div>
    </Container>
  )
}

export default App
