import React, { useEffect, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../components/Button'
import Select from '../components/Select'
import Input from '../components/Input'
import Loading from '../components/Loading'
import MatTable from '../components/MatTable'
import { addUser, getUsers, updateUser } from '../actions/userActions'
import {
  ADD_USER_RESET,
  GET_USERS_RESET,
  UPDATE_USER_RESET,
} from '../constants/userConstants'

const User = ({ history }) => {
  // * States
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [addUserModal, setAddUserModal] = useState(false)
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('')
  const [passwordModal, setPasswordModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [userToUpdate, setUserToUpdate] = useState('')

  // * Initialization
  const dispatch = useDispatch()

  // * Check for auth
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    // * Check if user info exists
    if (!userInfo) {
      history.push('/')
    }
  }, [userInfo, history])

  useEffect(() => {
    setLoading(true)
    dispatch(getUsers())
  }, [])

  // * Get Users
  const getUsersInfo = useSelector((state) => state.getUsersInfo)
  const { errorGetUsers, getUsersData } = getUsersInfo

  useEffect(() => {
    dispatch({ type: GET_USERS_RESET })
    if (getUsersData) {
      setLoading(false)
      setData(getUsersData)
    } else if (errorGetUsers) {
      setLoading(false)
      toast(errorGetUsers, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [getUsersData, errorGetUsers])

  const closeAddUserModal = () => {
    setUsername('')
    setName('')
    setRole('')
    setAddUserModal(false)
  }

  const addUserHandler = () => {
    if (!username || !name || !role) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(addUser(username, name, role))
    }
  }

  const addUserInfo = useSelector((state) => state.addUserInfo)
  const { loadingAddUser, addUserData, errorAddUser } = addUserInfo

  useEffect(() => {
    dispatch({ type: ADD_USER_RESET })
    if (addUserData) {
      setPassword(addUserData)
      setPasswordModal(true)
      closeAddUserModal()
      toast('User added successfully', {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else if (errorAddUser) {
      toast(errorAddUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [addUserData, errorAddUser])

  const closePasswordModal = () => {
    setPassword('')
    setPasswordModal(false)
    setTimeout(() => {
      dispatch(getUsers())
    }, 1000)
  }

  const updatedUser = (item) => {
    setUpdateModal(true)
    setUserToUpdate(item._id)
    setName(item.name)
  }

  const closeUpdateUserModal = () => {
    setUserToUpdate('')
    setName('')
    setUpdateModal(false)
  }

  const updateUserHandler = () => {
    if (!userToUpdate || !name) {
      toast('All the fields are mandatory', {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    } else {
      dispatch(updateUser(userToUpdate, name))
    }
  }

  const updateUserInfo = useSelector((state) => state.updateUserInfo)
  const { loadingUpdateUser, updateUserData, errorUpdateUser } = updateUserInfo

  useEffect(() => {
    dispatch({ type: UPDATE_USER_RESET })
    if (updateUserData) {
      closeUpdateUserModal()
      toast(updateUserData.msg, {
        type: 'success',
        hideProgressBar: true,
        autoClose: 2000,
      })
      setTimeout(() => {
        dispatch(getUsers())
      }, 1000)
    } else if (errorUpdateUser) {
      toast(errorUpdateUser, {
        type: 'error',
        hideProgressBar: true,
        autoClose: 2000,
      })
    }
  }, [updateUserData, errorUpdateUser])

  const headCells = [
    {
      field: 'username',
      title: 'Username',
      render: (rowData) => {
        return (
          <div
            className='font-bold text-md cursor-pointer text-blue-800 bg-blue-100 p-2 flex justify-center rounded'
            onClick={() => updatedUser(rowData)}
          >
            {rowData.username}
          </div>
        )
      },
    },
    {
      field: 'pin',
      title: 'Pin',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'role',
      title: 'Role',
    },
    {
      field: 'isActive',
      title: 'Is Active?',
      render: (rowData) => {
        return <p>{rowData.isActive === true ? 'Yes' : 'No'}</p>
      },
    },
  ]

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='w-full h-full'>
        <h1 className='text-2xl font-semibold'>Users</h1>
        <div className='bg-white shadow-md rounded px-8 py-4 my-4'>
          <div className='flex justify-end'>
            <Button
              custom='py-2'
              type='button'
              onClick={() => setAddUserModal(true)}
              text='Add a User'
            />
          </div>
        </div>
        <MatTable headCells={headCells} data={data} type='User' />
        {addUserModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Add User</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <div className='flex gap-5 mb-4 w-full'>
                      <Input
                        width='flex-1'
                        name='Username *'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <Input
                        width='flex-1'
                        name='Name *'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <Select
                      width='w-full mb-4'
                      name='Role *'
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      options={[
                        { id: 'Admin', title: 'Admin' },
                        { id: 'Employee', title: 'Employee' },
                      ]}
                    />
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeAddUserModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={addUserHandler}
                      disabled={loadingAddUser}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {passwordModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Password</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='flex-1'
                      name='Password *'
                      value={password}
                      onChange={null}
                      disabled
                    />
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closePasswordModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
        {updateModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
              <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                {/*content*/}
                <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  {/*header*/}
                  <div className='flex items-center justify-between py-3 px-3 border-b border-solid border-blueGray-200 rounded-t'>
                    <h3 className='text-lg font-semibold'>Update User</h3>
                  </div>
                  {/*body*/}
                  <div className='relative p-6 flex-auto'>
                    <Input
                      width='flex-1'
                      name='Name *'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <p className='text-sm'>
                      All the fields with * are mandatory
                    </p>
                  </div>
                  {/*footer*/}
                  <div className='flex items-center justify-end py-2 px-3 border-t border-solid border-blueGray-200 rounded-b'>
                    <button
                      className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                      type='button'
                      onClick={closeUpdateUserModal}
                    >
                      Close
                    </button>
                    <button
                      className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:bg-gray-300'
                      type='button'
                      onClick={updateUserHandler}
                      disabled={loadingUpdateUser}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
          </>
        )}
      </div>
    </>
  )
}

export default User
