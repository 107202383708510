import {
  ADD_MESSAGE_FAIL,
  ADD_MESSAGE_REQUEST,
  ADD_MESSAGE_RESET,
  ADD_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_REQUEST,
  DELETE_MESSAGE_RESET,
  DELETE_MESSAGE_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_RESET,
  GET_MESSAGES_SUCCESS,
} from '../constants/messageConstants'

export const getMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MESSAGES_REQUEST:
      return { loadingGetMessages: true }
    case GET_MESSAGES_SUCCESS:
      return { loadingGetMessages: false, getMessagesData: action.payload }
    case GET_MESSAGES_FAIL:
      return { loadingGetMessages: false, errorGetMessages: action.payload }
    case GET_MESSAGES_RESET:
      return {}
    default:
      return state
  }
}

export const addMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_MESSAGE_REQUEST:
      return { loadingAddMessage: true }
    case ADD_MESSAGE_SUCCESS:
      return { loadingAddMessage: false, addMessageData: action.payload }
    case ADD_MESSAGE_FAIL:
      return { loadingAddMessage: false, errorAddMessage: action.payload }
    case ADD_MESSAGE_RESET:
      return {}
    default:
      return state
  }
}

export const deleteMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MESSAGE_REQUEST:
      return { loadingDeleteMessage: true }
    case DELETE_MESSAGE_SUCCESS:
      return {
        loadingDeleteMessage: false,
        deleteMessageData: action.payload,
      }
    case DELETE_MESSAGE_FAIL:
      return {
        loadingDeleteMessage: false,
        errorDeleteMessage: action.payload,
      }
    case DELETE_MESSAGE_RESET:
      return {}
    default:
      return state
  }
}
