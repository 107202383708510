import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { logout } from '../actions/userActions'
import GroupIcon from '@material-ui/icons/Group'
import ExitToApp from '@material-ui/icons/ExitToApp'
import TransformOutlined from '@material-ui/icons/TransformOutlined'
import logo from '../logo.jpg'

const Sidebar = ({ route, history }) => {
  // * Initialization
  const dispatch = useDispatch()
  const location = useLocation()

  // * On logout click
  const logoutClick = () => {
    dispatch(logout())
  }

  // * States
  const [name, setName] = useState('')

  // * Get logged in user's info
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
    }
  }, [userInfo])

  return (
    <div className='w-1/5 min-h-screen absolute sm:relative bg-gray-800 shadow flex-col justify-between hidden sm:flex'>
      <div className='px-8'>
        <div className='h-16 w-full flex flex-row items-center gap-2 mt-5'>
          <img src={logo} alt='ParkOye Valet' className='w-10' />
          <h3 className='text-2xl font-semibold text-white'>Health & Safety</h3>
          {/* <p className='text-sm text-white mt-1'>(v{pjson.version})</p> */}
        </div>
        <ul className='mt-12'>
          <>
            <Link
              className={`flex w-full justify-between ${
                location.pathname === '/users'
                  ? 'text-gray-300'
                  : 'text-gray-600'
              } hover:text-gray-500 cursor-pointer items-center mb-6`}
              to='/users'
            >
              <div className='flex items-center'>
                <GroupIcon />
                <span className='text-md ml-2'>Users</span>
              </div>
            </Link>
            <Link
              className={`flex w-full justify-between ${
                location.pathname === '/queries'
                  ? 'text-gray-300'
                  : 'text-gray-600'
              } hover:text-gray-500 cursor-pointer items-center mb-6`}
              to='/queries'
            >
              <div className='flex items-center'>
                <TransformOutlined />
                <span className='text-md ml-2'>Quries</span>
              </div>
            </Link>
            <Link
              className={`flex w-full justify-between ${
                location.pathname === '/messages'
                  ? 'text-gray-300'
                  : 'text-gray-600'
              } hover:text-gray-500 cursor-pointer items-center mb-6`}
              to='/messages'
            >
              <div className='flex items-center'>
                <TransformOutlined />
                <span className='text-md ml-2'>Messages</span>
              </div>
            </Link>
          </>
        </ul>
      </div>
      <div className='px-8 border-t border-gray-700'>
        <ul className='w-full flex items-center justify-between bg-gray-800'>
          <li
            className='flex items-center w-full cursor-pointer text-white pt-5 pb-3'
            onClick={logoutClick}
          >
            <ExitToApp />
            <div className='flex flex-col'>
              <span className='text-sm ml-6'>{name}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
