export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL'
export const GET_MESSAGES_RESET = 'GET_MESSAGES_RESET'

export const ADD_MESSAGE_REQUEST = 'ADD_MESSAGE_REQUEST'
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS'
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL'
export const ADD_MESSAGE_RESET = 'ADD_MESSAGE_RESET'

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST'
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL'
export const DELETE_MESSAGE_RESET = 'DELETE_MESSAGE_RESET'
